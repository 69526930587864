var map;

function initMap() {
    // Create a map object and specify the DOM element for display.
    map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: -33.4727879, lng: -70.6298313},
      scrollwheel: false,
      zoom: 8
    });
    refresh_markets();
}


var markersArray = [];
//var infowindow;
var selected = "";
function create_marker(marker_lat,marker_lng,marker_content){
	myLatlng = {lat: parseFloat(marker_lat), lng: parseFloat(marker_lng)};
	var infowindow = new google.maps.InfoWindow({
	    content: marker_content
	});
	var marker = new google.maps.Marker({
	    position: myLatlng,
	    map: map
	});
	marker.addListener('click', function() {
	    infowindow.open(map, marker);
	});
	if(selected!=""){
		if(selected==(marker_lat+"/"+marker_lng)){
			map.setCenter(myLatlng);
			map.setZoom(14);
			infowindow.open(map, marker);
		}
	}
	markersArray.push(marker);
}


function clearOverlays() {
 	for (var i = 0; i < markersArray.length; i++ ) {
    	markersArray[i].setMap(null);
  	}
  	markersArray.length = 0;
}

function viewMarket(lat,lng){
	selected = lat+"/"+lng;
	refresh_markets();
	ir_a("puntos_cover");
}

function selectMarket(location){
	var lat = location.split(",")[0];
	var lon = location.split(",")[1];
	viewMarket(lat,lon);
}

function refresh_markets(){
	clearOverlays();
	$.each( $(".punto_item"), function( i, punto ) {
		lat = $(punto).data("lat");
		lng = $(punto).data("lon");
		local_content = $(punto).find(".content_market").html();
		if(lat!=""&&lng!="") create_marker(lat,lng,local_content);
	});
}
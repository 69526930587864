jQuery(document).ready(function($){
	$('.carousel-inner').each(function() {
		if ($(this).children('div').length === 1) $(this).siblings('.carousel-control, .carousel-indicators').hide();
	});

	var menu_open = false;
	$(".open-menu").click(function(){
		var h = $("#menu-menu-principal").outerHeight();
		if(!menu_open){
			$(this).parent().parent().find(".nav_menu").height(h);	
			menu_open=true;
		}else{
			$(this).parent().parent().find(".nav_menu").height(0);	
			menu_open=false;
		}
	});

	$(".link_animate").click(function(){
        ir_a(this.hash.slice(1));
        return false;
    });
});

function ir_a(anchor){
  var $target = $('[id='+anchor+']');
   if ($target.length) {
       var targetOffset = $target.offset().top;
       var widthCurrent = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
       if(widthCurrent > 767){
         var targetOffset = (targetOffset-70)
       }
       $('html,body').animate({scrollTop: (targetOffset)}, 500);
       return false;
   }
}